import { useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../style/sidebar.css";
import Badge from "@mui/material/Badge";
import { CartBadgeContext } from "../context/CartBadgeContext.js";
import "../style/header.css";
import axios from "axios";
import { UserContext } from "../context/UserContext";

function SideBar(props) {
  const navigate = useNavigate();
  const sideBar = useRef(null);
  const { badgeValue } = useContext(CartBadgeContext);
  const { userDetailsContext } = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_API_URL;
  // console.log(dialogBox)
  function closeSideBar() {
    sideBar.current.style.display = "none";
    if (props.navigate !== null) {
      navigate(props.navigate);
    }
  }
  // Close the modal if clicking outside of the modal content
  window.onclick = function (event) {
    if (event.target === sideBar.current) {
      sideBar.current.style.display = "none";
    }
  };

  async function handleLogOut() {
    try {
      const response = await axios.post(
        `${apiUrl}/protected/logout`,
        {},
        { withCredentials: true }
      );
      console.info(response);
      window.location.reload();
      // navigate("/");
    } catch (err) {
      console.error("Logout failed:", err.response?.data || err.message);
    }
  }
  return (
    // Popup dialog box
    <div ref={sideBar} id="sideBar" className="modal_slider">
      <div className="modal-content_slider">
        <span id="closeDialogBtn" className="close" onClick={closeSideBar}>
          &times;
        </span>
        <h3>
          Hello,
          <br />
          {userDetailsContext.user_name}
        </h3>
        <br />
        {/* <button id="confirmBtn">Confirm</button> */}
        <button onClick={closeSideBar} id="cancelBtn">
          {props.data.bookings}
        </button>
        <Badge
          badgeContent={badgeValue}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          slotProps={{ badge: { className: "my-badge" } }}
        ></Badge>
        <button onClick={closeSideBar} id="cancelBtn">
          {props.data.cart}
        </button>
        <button onClick={closeSideBar} id="cancelBtn">
          {props.data.freeDates}
        </button>
        <button onClick={handleLogOut} id="cancelBtn">
          {props.data.logout}
        </button>
      </div>
    </div>
  );
}

export default SideBar;
