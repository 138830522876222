import Header from "./Header";
import Button from "./Button";
import Footer from "./Footer";

function Welcome() {
   const menuOptionsProps = [
    {
      name: "Sign In",
      address: "/signin",
    },
    {
      name: "Sign Up",
      address: "/signup",
    },
    {
      name: "Services",
      address: "/services",
    },
    {
      name: "About",
      address: "/about",
    },

    {
      name: "Contact",
      address: "/contact",
    },
  ];
  return (
    <div>
      <div id="welcome">
        <Header menuOptionsProps={menuOptionsProps}/>
        {/* <Button style = {{display: "inline"}} title="Make Up"/>
        <Button style = {{display: "inline"}} title="Cosmatics"/>
        <Button style = {{display: "inline"}}  title="Jewellary"/> */}
        <div id="greeting_bg">
          {/* <i className="fa-solid fa-ribbon"></i> */}
          <div className="greeting_definition">
            <h1>
              Welcome to <br />
              BeautyOne
            </h1>
            <p>
              A final destination for all kinds of Make Up, Artificial Jewellery
              and Cosmatics. Step into a world where makeup isn't just a routine
              but a transformative experience, where confidence is enhanced and
              individuality celebrated.
            </p>
          </div>
        </div>
        <div id="artist">
          <div>
            <div>Gauri Untwal</div>
            <div>MakeUp Artist</div>
          </div>
        </div>
        <Footer />
      </div>
      <br />
    </div>
  );
}

export default Welcome;
