import "../style/services.css";
import Header from "./Header";
import Footer from "./Footer";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
function Services() {

  const menuOptionsProps = [
    {
      name: "Sign In",
      address: "/signin",
    },
    {
      name: "Sign Up",
      address: "/signup",
    },
    {
      name: "About",
      address: "/about",
    },

    {
      name: "Contact",
      address: "/contact",
    },
  ];

  const navigate = useNavigate();

  function handleClick() {
    navigate("/signup");
  }

  return (
    <div id="services">
      <Header menuOptionsProps={menuOptionsProps}/>
      <div id="services_img"></div>
      <div id="services_content">
        <h1>Our Services</h1>
        <br />
        <h6>
          Welcome to BeautyOne
          <br />– A Beauty Destination!
        </h6>
        <br />
        {/* Design for this grid present in about.css */}
        <div id="card_grid">
          <div>
            <h6>Hair Care Services</h6>
            <ul>
              <li>
                <strong>
                  <em>Haircut & Styling</em>
                </strong>
                <ul>
                  <li>Haircut for women.</li>
                  <li>Blow-dry styling.</li>
                  <li>Hair straightening and curling.</li>
                </ul>
              </li>
              <li>
                <strong>
                  <em>Hair Coloring</em>
                </strong>
                <ul>
                  <li>Hair spa.</li>
                  <li>Keratin treatment.</li>
                  <li>
                    Hair smoothening, rebonding and straightening treatments.
                  </li>
                  <li>Nano plasia treatment.</li>
                </ul>
              </li>
              <li>
                <strong>
                  <em>Hair Extensions</em>
                </strong>
                <ul>
                  <li>Clip-in extensions</li>
                </ul>
              </li>
            </ul>
          </div>
          <div>
            <h6>Makeup Services</h6>
            <ul>
              <li>
                <strong>
                  <em>Bridal Makeup</em>
                </strong>
              </li>
              <li>
                <strong>
                  <em>Party Makeup</em>
                </strong>
              </li>
              <li>
                <strong>
                  <em>HD Makeup</em>
                </strong>
              </li>
              <li>
                <strong>
                  <em>Simple or Day Makeup</em>
                </strong>
              </li>
            </ul>
          </div>
          <div>
            <h6>Eyebrows & Lashes</h6>
            <ul>
              <li>
                <strong>
                  <em>Eyebrow Shaping & Tinting</em>
                </strong>
              </li>
              <li>
                <strong>
                  <em>Eyelash Extensions</em>
                </strong>
              </li>
              <li>
                <strong>
                  <em>Eyelash Tinting</em>
                </strong>
              </li>
              <li>
                <strong>
                  <em>Eyelash Lifting</em>
                </strong>
              </li>
            </ul>
          </div>
          <div>
            <h6>Hair Removal Services</h6>
            <ul>
              <li>
                <strong>
                  <em>Waxing</em>
                </strong>
                <ul>
                  <li>Full body waxing</li>
                  <li>Arm, leg, and underarm waxing</li>
                </ul>
              </li>
              <li>
                <strong>
                  <em>Threading</em>
                </strong>
                <ul>
                  <li>Eyebrow threading</li>
                  <li>Upper lip threading</li>
                  <li>Forehead threading</li>
                </ul>
              </li>
            </ul>
          </div>
          <div>
            <h6>Hand & Foot Care</h6>
            <ul>
              <li>
                <strong>
                  <em>Manicure</em>
                </strong>
              </li>
              <li>
                <strong>
                  <em>Pedicure</em>
                </strong>
                <ul>
                  <li>Foot scrub and massage</li>
                </ul>
              </li>
            </ul>
          </div>
          <div>
            <h6>Body Treatments</h6>
            <ul>
              <li>
                <strong>
                  <em>Body Massage</em>
                </strong>
              </li>
            </ul>
            <ul>
              <li>
                <strong>
                  <em>Body Polishing</em>
                </strong>
              </li>
            </ul>
          </div>
          <div>
            <h6>Additional Services</h6>
            <ul>
              <li>
                <strong>
                  <em>Bridal Packages (including pre-wedding rituals)</em>
                </strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Button
        style={{ marginTop: "30px" }}
        title="Book Now"
        onClick={handleClick}
      />
      <Footer />
    </div>
  );
}
export default Services;
