import { UserContext } from "../context/UserContext";
import { useContext, useEffect, useState } from "react";
import Header from "./Header";
import axios from "axios";
import Footer from "./Footer";
import "../style/home.css";
import ServiceCard from "./ServiceCard";
import { BottomAppBar } from "./BottomAppBar";
import { Cart } from "./Cart.jsx";
import { CartContext } from "../context/CartContext.js";
import BookingPicker from "./BookingPicker.jsx";

function Home() {
  const apiUrl = process.env.REACT_APP_API_URL;
  // async function handleSubmit() {
  //   try {
  //     const response = await axios(`${apiUrl}/protected`, {
  //       withCredentials: true,
  //     });
  //     console.log(response);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }
  const { userDetailsContext } = useContext(UserContext);
  const { cartDetails } = useContext(CartContext);
  const cart = useContext(CartContext);
  const buttonStyle = {
    width: "max-content",
    justifySelf: "center",
  };
  const serviceDetails = [
    {
      categoryId: "1",
      categoryName: "Hair Care Services",
      details: [
        {
          serviceId: 1,
          price: 100,
          name: "Haircut for women",
        },
        {
          serviceId: 2,
          price: 150,
          name: "Blow-dry styling",
        },
        {
          serviceId: 3,
          price: 200,
          name: "Hair Straightening",
        },
        {
          serviceId: 19,
          price: 200,
          name: "Hair Curling",
        },
        {
          serviceId: 4,
          price: 200,
          name: "Hair spa",
        },
        {
          serviceId: 5,
          price: 150,
          name: "Keratin treatment",
        },
        {
          serviceId: 6,
          price: 200,
          name: "Hair smoothening treament",
        },
        {
          serviceId: 7,
          price: 200,
          name: "Rebonding treatments",
        },
        {
          serviceId: 8,
          price: 200,
          name: "Straightening treatments",
        },
        {
          serviceId: 9,
          price: 110,
          name: "Nano plasia treatment",
        },
        {
          serviceId: 10,
          price: 150,
          name: "Clip-in extensions",
        },
      ],
    },
    {
      categoryId: "2",
      categoryName: "Makeup Services",
      details: [
        {
          serviceId: 11,
          price: 100,
          name: "Bridal Makeup",
        },
        {
          serviceId: 12,
          price: 150,
          name: "Party Makeup",
        },
        {
          serviceId: 13,
          price: 200,
          name: "HD Makeup",
        },
        {
          serviceId: 14,
          price: 200,
          name: "Simple or Day Makeup",
        },
      ],
    },
    {
      categoryId: "3",
      categoryName: "Eyebrows & Lashes",
      details: [
        {
          serviceId: 15,
          price: 100,
          name: "Eyebrow Shaping & Tinting",
        },
        {
          serviceId: 16,
          price: 150,
          name: "Eyelash Extensions",
        },
        {
          serviceId: 17,
          price: 120,
          name: "Eyelash Tinting",
        },
        {
          serviceId: 18,
          price: 200,
          name: "Eyelash Lifting",
        },
      ],
    },
  ];
  function hanldeCartButtonClick() {
    const cart = document.getElementById("cart");
    if (cart.style.display === "none" || cart.style.display === "") {
      if (cartDetails.bookingTime === "") {
        document.getElementById("booking_picker").style.display = "block";
      }
      cart.style.display = "flex";
    } else {
      cart.style.display = "none";
    }
  }
  function handleBookingPickerClick() {
    const bookingPicker = document.getElementById("booking_picker");
    if (
      bookingPicker.style.display === "none" ||
      bookingPicker.style.display === ""
    ) {
      bookingPicker.style.display = "block";
    } else {
      bookingPicker.style.display = "none";
    }
  }
  return (
    <div id="home">
      <Header auth={true} userName={userDetailsContext.user_name}></Header>
      {serviceDetails.map((category, index) => (
        <div key={index} id="service">
          <span key={index + 1}>{category.categoryName}</span>
          <div key={index + 2} id="service_container">
            {category.details.map((service, index) => (
              <ServiceCard
                key={"S" + index}
                serviceId={service.serviceId}
                price={service.price}
                name={service.name}
              />
            ))}
          </div>
        </div>
      ))}

      {/* <Button title={"Go to cart"} style={buttonStyle} /> */}
      <BookingPicker buttonTitle={"Continue"} />
      <Cart cart={cart} buttonTitle={"Book"} />
      <BottomAppBar
        showBookingPicker={handleBookingPickerClick}
        showCart={hanldeCartButtonClick}
      />
    </div>
  );
}

export default Home;
