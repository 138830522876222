import React from "react";
import { useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../style/bookingpicker.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { CartContext } from "../context/CartContext";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);
function BookingPicker(props) {
  const navigate = useNavigate();
  const bookingPicker = useRef(null);
  const [value, setValue] = React.useState(dayjs());
  const { setCartDetails } = useContext(CartContext);
  function closeBookingPicker() {
    bookingPicker.current.style.display = "none";
    // if (props.navigate !== null) {
    //   navigate(props.navigate);
    // }
    setCartDetails((prevData) => ({
      ...prevData,
      bookingTime: value.format("DD/MM/YYYY HH:mm:ss"),
    }));
  }

  // Close the modal if clicking outside of the modal content
  function handleClickOutsideModal(event) {
    if (event.target === bookingPicker.current) {
      //   bookingPicker.current.style.display = "none";
      closeBookingPicker();
    }
  }
  window.addEventListener("click", handleClickOutsideModal);
  return (
    // Popup dialog box
    <div
      ref={bookingPicker}
      id="booking_picker"
      className="modal_booking_picker"
    >
      <div className="modal_content_booking_picker">
        <span
          id="close_booking_picker"
          className="closeclose_booking_picker"
          onClick={closeBookingPicker}
        >
          &times;
        </span>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Booking date & time"
            value={value}
            timezone="Asia/Kolkata"
            onChange={(newValue) => {
              setValue(newValue);
            }}
          />
        </LocalizationProvider>
        {/* <input type="datetime-local" name="" id="" /> */}
        <button
          onClick={closeBookingPicker}
          id="cancelBtn_booking_picker"
          style={{ marginBottom: "0" }}
        >
          {props.buttonTitle}
        </button>
      </div>
    </div>
  );
}

export default BookingPicker;
